<template>
  <!-- eslint-disable -->
  <div>
    <b-card>
      <b-row>
        <!-- User Info: Left col -->
        <b-col
          cols="21"
          xl="6"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0" v-if="contractData.name">
                  {{ contractData.name.toUpperCase() }}
                </h4>
                <span class="card-text" v-if="contractData.company">{{
                  contractData.company.business_name
                }}</span>
              </div>
              <div
                class="d-flex flex-wrap"
                v-if="userData.role != 'regulador' && !isFinish"
              >
                <b-form-group>
                  <b-form-radio-group
                    v-model="contractData.contractor_status"
                    :options="optionsStatus"
                    name="buttons-1"
                    buttons
                    @change="changeRadioContractorStatus()"
                    button-variant="outline-primary"
                  />
                </b-form-group>
              </div>
              <div
                class="d-flex flex-wrap"
                v-if="userData.role != 'contratista'"
              >
                <b-form-group>
                  <b-form-radio-group
                    v-model="contractData.is_finished"
                    :options="optionsIsFinished"
                    name="buttons-1"
                    buttons
                    @change="changeRadioContractorIsFinished($event)"
                    button-variant="outline-danger"
                  />
                </b-form-group>
              </div>
            </div>
          </div>
        </b-col>

        <!-- Right Col: Table -->
        <b-col cols="12" xl="6">
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon icon="UserIcon" class="mr-75" />
                <span class="font-weight-bold">Responsable</span>
              </th>
              <td class="pb-50">
                {{ contractData.responsible_sst }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="UsersIcon" class="mr-75" />
                <span class="font-weight-bold">Nro Trabajadores</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ contractData.qty_workers }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="SettingsIcon" class="mr-75" />
                <span class="font-weight-bold">Desarrollado por</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ contractData.development_by }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="CalendarIcon" class="mr-75" />
                <span class="font-weight-bold">Fecha Evaluación</span>
              </th>
              <td class="pb-50">
                {{ moment(contractData.evaluation_date).format("yyyy-MM-DD") }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon icon="CalendarIcon" class="mr-75" />
                <span class="font-weight-bold">Fecha de expiración</span>
              </th>
              <td>
                {{ moment(contractData.expiration_date).format("yyyy-MM-DD") }}
              </td>
            </tr>
            <hr />
            <tr>
              <th>
                <span class="font-weight-bold"
                  >NIVEL GLOBAL DE CUMPLIMIENTO</span
                >
              </th>
              <td>
                <b-badge
                  pill
                  :variant="
                    contractData.completion_percentage < 60
                      ? 'danger'
                      : contractData.completion_percentage < 85
                      ? 'warning'
                      : 'success'
                  "
                  >{{ contractData.completion_percentage }}</b-badge
                >
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body>
      <b-table-simple hover responsive class="rounded-bottom mb-0">
        <b-thead head-variant="light">
          <b-tr>
            <b-th colspan="2"> Titulo </b-th>
            <b-th colspan="1"> Descripcion </b-th>
            <b-th colspan="1"> Ref. Legal </b-th>
            <b-th colspan="1"> Cumplimiento </b-th>
            <b-th colspan="1"> %Cumplimiento </b-th>
            <b-th colspan="7"> Hallazgos </b-th>
            <b-th colspan="1"> Editado </b-th>
            <b-th colspan="2"> Acciones </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(item, index) in contractDetail" :key="index">
            <b-th v-if="item.title" :rowspan="item.childrenLength" colspan="2"
              >{{ item.numberParent }}. {{ item.title }}
            </b-th>
            <b-td colspan="1" class="text-left"
              >{{ item.number }}. {{ item.subtitle }}
            </b-td>
            <b-td colspan="1"> {{ item.ref_legal }} </b-td>
            <b-td colspan="1">
              <b-form-select
                v-if="
                  userData.role != 'contratista' && !contractData.is_finished
                "
                v-model="item.compliance"
                :options="optionsCompliance"
                @change="
                  updateDetailContract(
                    $event,
                    'compliance',
                    item.compliance,
                    item.idChildren,
                    index
                  )
                "
              />
              <span v-else>{{ item.compliance }}</span>
            </b-td>
            <b-td colspan="1">
              <b-form-input
                v-if="
                  userData.role != 'contratista' && !contractData.is_finished
                "
                id="basicInput"
                type="number"
                :readonly="item.compliance == 'NA'"
                v-model="item.compliance_percentage"
                placeholder="Porcentaje"
                @keypress="
                  updateDetailContract(
                    $event,
                    'compliance_percentage',
                    item.compliance_percentage,
                    item.idChildren,
                    index
                  )
                "
                v-on:blur="
                  updateDetailContract(
                    $event,
                    'compliance_percentage',
                    item.compliance_percentage,
                    item.idChildren,
                    index
                  )
                "
                @keypress.enter="
                  updateDetailContract(
                    $event,
                    'compliance_percentage',
                    item.compliance_percentage,
                    item.idChildren,
                    index
                  )
                "
              />
              <span v-else>{{ item.compliance_percentage }}</span>
            </b-td>
            <b-td colspan="7">
              <b-form-textarea
                v-if="
                  userData.role != 'contratista' && !contractData.is_finished
                "
                id="basicInput"
                :value="item.finding"
                :readonly="item.compliance == 'NA'"
                v-model="item.finding"
                rows="3"
                style="width: 12rem"
                @keypress.enter="
                  updateDetailContract(
                    $event,
                    'finding',
                    item.finding,
                    item.idChildren,
                    index
                  )
                "
              />
              <span v-else>{{ item.finding }}</span></b-td
            >
            <b-td colspan="1"> {{ item.doEdit }} </b-td>
            <b-td colspan="2">
              <b-button
                size="sm"
                class=""
                @click="
                  showTemplate(item.idChildren, item.number, item.subtitle)
                "
                v-b-tooltip.hover.v-warning
                title="TimeLine"
                variant="flat-warning"
              >
                <feather-icon icon="ClockIcon" />
              </b-button>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
    <b-modal
      ref="my-modal"
      class="my_modal"
      hide-footer
      title=""
      centered
      :size="addComent ? 'lg' : ''"
    >
      <b-card class="card_content">
        <b-row>
          <b-col :md="contractData.is_finished ? '12' : '8'">
            <span>{{ title }}</span>
          </b-col>
          <b-col v-if="!contractData.is_finished" md="4" class="text-right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :variant="!addComent ? 'danger' : 'warning'"
              @click="addComent = !addComent"
            >
              <span v-if="!addComent">Agregar</span>
              <span v-else>Ocultar</span>
            </b-button>
          </b-col>
        </b-row>
        <hr v-if="timeLine.length > 0" />
        <div class="content_main">
          <b-row :class="{ content_form: timeLine.length == 0 }">
            <b-col
              :md="addComent && timeLine.length > 0 ? '6' : '12'"
              :class="{ content_timeline: timeLine.length > 0 }"
            >
              <app-timeline>
                <app-timeline-item
                  v-for="(item, index) in timeLine"
                  :key="index"
                  variant="primary"
                >
                  <div
                    class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                  >
                    <h6>{{ item.user.name + " " + item.user.last_name }}</h6>
                    <small class="text-muted">{{
                      moment(item.created_at).format("yyyy-MM-DD")
                    }}</small>
                  </div>
                  <p>{{ item.description }}</p>
                  <p v-if="item.file_path != ''">
                    <b-img
                      :src="require('@/assets/images/pdf.png')"
                      height="auto"
                      width="20"
                      class="mr-1"
                    />
                    <a :href="item.file_path" target="_blank"
                      ><span class="align-bottom">Descargar</span></a
                    >
                  </p>
                </app-timeline-item>
              </app-timeline>
            </b-col>
            <b-col md="6" v-if="addComent">
              <validation-observer ref="simpleRules">
                <b-form
                  :class="{ section_add: timeLine.length > 0 }"
                  @submit.prevent="addAction"
                >
                  <b-row>
                    <b-col cols="12" md="12">
                      <b-form-group label="Comentario" label-for="comentario">
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="comment"
                        >
                          <b-form-textarea
                            placeholder="Comentario"
                            id="comentario"
                            rows="3"
                            no-resize
                            v-model="comment"
                          />
                          <small v-if="errors.length > 0" class="text-danger"
                            >El campo es requerido</small
                          >
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="12">
                      <b-form-group label="Archivo" label-for="files">
                        <b-form-file
                          id="files"
                          placeholder="Suba un archivo"
                          drop-placeholder="Drop file here..."
                          v-model="fileAdd"
                          accept=".pdf"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    type="submit"
                    >Agregar</b-button
                  >
                </b-form>
              </validation-observer>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-modal>
  </div>
</template>
<script>
/* eslint-disable */
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BTableSimple,
  BCardTitle,
  BCardBody,
  VBTooltip,
  BModal,
  BSidebar,
  BFormTextarea,
  VBToggle,
  BFormFile,
  BFormRadioGroup,
  BFormSelect,
  BImg,
  BBadge,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import ContractService from "@/services/ContractService";
import UserService from "@/services/UserService";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BAvatar,
    BTableSimple,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BThead,
    BImg,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BCardTitle,
    BCardBody,
    BFormFile,
    BModal,
    AppTimeline,
    AppTimelineItem,
    BSidebar,
    BFormRadioGroup,
    BFormSelect,
    BBadge,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      required,
      contractData: {},
      contractDetail: [],
      timeLine: [],
      title: "",
      comment: "",
      fileAdd: null,
      detailId: null,
      addComent: false,
      isFinish: false,
      optionsStatus: [
        { text: "Para revisar", value: "review" },
        { text: "Borrador", value: "draft" },
      ],
      optionsIsFinished: [
        { text: "Pendiente", value: false },
        { text: "Terminado", value: true },
      ],
      userData: {},
      optionsCompliance: [
        { value: "", text: "", disabled: true },
        { value: "SI", text: "Si" },
        { value: "NO", text: "No" },
        { value: "PARCIAL", text: "Parcial" },
        { value: "NA", text: "NA" },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async changeRadioContractorStatus() {
      const data = {
        type: "contractor_status",
        value: this.contractData.contractor_status,
      };
      const resp = await ContractService.updateContractSingle(
        this.$route.params.id,
        data,
        this.$store
      );
      console.log("changeRadioContractorStatus", resp);
    },
    async changeRadioContractorIsFinished(e) {
      console.log("e", e);
      if (e) {
        this.$swal({
          title: "¿Desea dar por terminado?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Dar por finalizado",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.updateSingles();
          } else {
            this.contractData.is_finished = false;
          }
        });
      } else {
        this.updateSingles();
      }
    },
    async updateSingles() {
      const data = {
        type: "is_finished",
        value: this.contractData.is_finished,
      };
      const resp = await ContractService.updateContractSingle(
        this.$route.params.id,
        data,
        this.$store
      );
    },
    async updateDetailContract(e, type, value, idDetail, index) {
      if (type != "finding") {
        if (value < 0) {
          this.contractDetail[index].compliance_percentage = 0;
        } else if (value > 100) {
          this.contractDetail[index].compliance_percentage = 100;
        }
        if (type == "compliance_percentage") {
          if (e.charCode != 13 && e.type != "blur") return;
        } else {
          if (value == "NA") {
            this.contractDetail[index].compliance_percentage = 100;
          }
        }
      }
      const data = {
        type: type,
        value: value,
      };

      const resp = await ContractService.updateContractDetail(
        idDetail,
        data,
        this.$store
      );
      console.log("respUpdateDetail", resp);
      if (type != "finding") {
        if (resp.success && resp.dataContract) {
          this.contractData.completion_percentage = parseFloat(
            resp.dataContract.completion_percentage
          );
        }
      }
    },
    async showTemplate(id, number, title) {
      this.detailId = id;
      this.title = number + " " + title;
      this.getTimeLine(id);
      this.$refs["my-modal"].show();
    },
    async getTimeLine(id) {
      console.log({ id });
      const resp = await ContractService.getTimeLine(id, this.$store);
      console.log("ENTRÓ A TIMELINE");
      if (resp.success) {
        this.timeLine = resp.data;
        console.log("linea541", resp);
      }
    },
    async addAction() {
      const isValid = await this.$refs.simpleRules.validate();
      if (isValid) {
        let formData = new FormData();
        formData.append("pdf", this.fileAdd);
        formData.append("description", this.comment);
        formData.append("user_id", parseInt(this.userData.id));
        formData.append("contract_detail_id", parseInt(this.detailId));
        const resp = await ContractService.saveAction(formData, this.$store);
        if (resp) {
          await this.getTimeLine(this.detailId);
          console.log("ENTRO AL ADDCTION");
          this.comment = "";
          this.fileAdd = null;
        }
      }
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    async getData() {
      if (localStorage.getItem("userData")) {
        this.userData = JSON.parse(localStorage.getItem("userData"));
      }
      const resp = await ContractService.getContract(
        this.$route.params.id,
        this.$store
      );
      if (resp.success) {
        console.log({ "linea 572": resp });
        this.contractData = resp.data;
        var arrayFormatTable = [];
        for (let i = 0; i < this.contractData.contract_detail.length; i++) {
          for (
            let j = 0;
            j < this.contractData.contract_detail[i].children.length;
            j++
          ) {
            var element = {
              numberParent: this.contractData.contract_detail[i].number,
              title:
                j == 0
                  ? this.contractData.contract_detail[i].description
                  : null,
              childrenLength:
                j == 0
                  ? this.contractData.contract_detail[i].children.length
                  : null,
              idParent: this.contractData.contract_detail[i].id,
              idChildren: this.contractData.contract_detail[i].children[j].id,
              number: this.contractData.contract_detail[i].children[j].number,
              subtitle:
                this.contractData.contract_detail[i].children[j].description,
              ref_legal:
                this.contractData.contract_detail[i].children[j].ref_legal,
              compliance:
                this.contractData.contract_detail[i].children[j].compliance,
              compliance_percentage:
                this.contractData.contract_detail[i].children[j]
                  .compliance_percentage,
              finding: this.contractData.contract_detail[i].children[j].finding,
              doEdit:
                this.contractData.contract_detail[i].children[j].action.length >
                0
                  ? "Si"
                  : "No",
            };
            console.log("element", element);
            arrayFormatTable.push(element);
          }
        }
        this.contractDetail = arrayFormatTable;
        console.log("this.contractDetail", this.contractDetail);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// .my_modal {
//   transform: translateX(0) 2s;
// }
.modal-dialog {
  background: aqua;
}
.card_content {
  box-shadow: none;
}
.card_title {
  margin-bottom: 0px;
}
.card_body {
  padding-bottom: 2em;
  padding-top: 0px;
  display: flex;
  justify-content: end;
  padding-right: 0px;
}
.content_main {
  .content_timeline {
    max-height: 450px;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }
  .content_form {
    display: flex;
    justify-content: center;
  }
}
</style>
